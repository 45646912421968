.ncc-setting-header {
  .ant-typography {
    color: #414141;
    line-height: 30px;
    font-size: 17px;
  }
}
.ncc-setting {
  ol {
    li {
      font-size: 15px;
      font-weight: bold;
    }
  }
}
.ncc-upload-certificate-button {
  height: 40px;
}
.ncc-content-disable {
  opacity: 0.5;
  pointer-events: none;
}
.ncc-radio {
  font-weight: bold;
  margin: 20px;
  font-size: 18px;
}
.ncc-setting-file-uploaded {
  color: rgb(16, 167, 16);
  border-color: rgb(16, 167, 16);
}
