@import "../../utils/color/Colors.scss";
.home {
  .user-header {
    background-color: #e0e5ec;
    padding: 20px 0;
    figure {
      max-width: 200px;
      margin: 0;
      .ant-avatar {
        display: block;
        margin: 0px auto 10px;
      }
      figCaption {
        text-align: center;
        font-size: 21px;
        font-weight: 600;
        line-height: 23px;
      }
    }

    .application-status-wrapper {
      text-align: center;
      .application-status {
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        .orange {
          color: $orange;
          background-color: #ffa50036;
          padding: 2px 10px;
          border-radius: 11px;
        }
        .red {
          color: red;
          background-color: #ff00001c;
          padding: 2px 10px;
          border-radius: 11px;
        }
      }
    }

    .log-out {
      display: block;
      text-align: right;
      margin-right: 0px;
    }
  }
  main {
    .bearer-ncc-note-title {
      text-align: left;
      padding: 0;
      font-style: italic;
      color: #fff;
      font-weight: bold;
    }
    .bearer-info-title {
      text-align: left;
      margin-top: 25px;
      color: #273b5e;
      font-family: "gBold";
      font-size: 25px;
    }
    .bearer-info {
      background-color: #69b1ff;
      padding: 24px;
      border-radius: 8px;
      color: #fff;
      font-size: 16px;
      max-height: 400px;
      overflow-y: scroll;
      line-height: 30px;
      text-align: justify;
    }
  }
  .user-footer {
    .policy-agreement-check-box {
      margin-right: 5px;
      margin-top: 4px;
      float: left;
    }
    .policy-agreement {
      color: #414141;
      line-height: 30px;
      font-size: 17px;
      input {
        height: 20px;
        padding: 0;
        width: 250px;
        border: none;
        font-size: 20px;
        border-bottom: 1px solid #000;
        border-radius: 0;
        margin: 0 5px;
        text-align: center;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .submit-btn {
      margin: 10px auto 50px;
      .submit {
        margin: 0px auto;
        display: block;
        height: 45px;
        width: 200px;
      }
    }
  }
}

@media (max-width: 767px) {
  .home {
    .user-header {
      figure {
        margin: 10px auto;
        figCaption {
          text-align: center;
        }
      }

      .application-status {
        text-align: center;
        padding: 20px 0px;
        .status {
          margin: 5px;
          padding: 5px;
        }
      }

      .log-out {
        text-align: center;
      }
    }
  }
}
